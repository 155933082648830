import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Thumbs } from 'swiper';

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Thumbs]);

new Swiper('.box-carousel', {
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 40,
    grabCursor: true,
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 1,
        },
    },
});

new Swiper('.box-lg-carousel', {
    loop: true,
    loopedSlides: 2,
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 40,
    grabCursor: true,
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});

new Swiper('.box-leader-carousel', {
    loop: true,
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 40,
    grabCursor: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});